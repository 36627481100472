<template>
    <div>
      <headBack>
        <template v-slot:title>
          <div class='title'>
            下单并支付
          </div>
        </template>
      </headBack>
      <div class='header_cont'></div>
      <div class='pay_top'>
        <div class='top_dec'>
          <img src='https://static.yihu365.cn/img/h5Img/assets/img/home/pay_icon.png' class='pay_icon'/>
          <div>
            <div class='top_name'>请您在30分钟内完成支付</div>
          </div>
        </div>
      </div>

      <div class='pay_content'>
        <div class='pay_list'>
          <div>价格</div>
          <div>￥{{ fee }}</div>
        </div>
        <div class='pay_list'>
          <div>代金券</div>
          <div class='pay_right' @click='popActive'>
            <div>{{couponText}}</div>
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
      <div class='btn_btm_cont'>
        <div class='btn_inner'>
          <div class='money'><span class='small'>实际支付：¥</span>{{totle}}</div>
          <van-button type="success" color='#00C291' class='submit_btn' round @click='chosePayment'>立即支付</van-button>
        </div>
      </div>


      <van-popup
          v-model:show="couponShow"
          position="bottom"
          closeable
      >
        <div class='concel_title'>选择代金劵</div>
        <van-radio-group v-model="coupon_radio">
          <div  v-for='(item,index) in couponList' :key='index' class='pop_item' @click='check_radio(index)'>
            <div class="rad">
              <van-radio :name="index" checked-color="#00C291"></van-radio>
            </div>
            <div class='list'>
              <div class='list_money'>
                <span>¥</span>{{item.price}}
              </div>
              <div class='dero_cont'>
                <div>
                  <div class='span'></div>{{item.remark}}</div>
                <div>
                  <div class='span'></div>{{item.priceLimitDesc}}</div>
                <div>
                  <div class='span'></div>{{item.endTimeDesc}}</div>
              </div>
            </div>
          </div>
        </van-radio-group>
        <div class='btm_btn_cont'>
          <van-button type="success" color='#00C291' class='submit_btn' round block @click='nousercoupon'>不使用代金劵</van-button>
        </div>
      </van-popup>

    </div>
</template>

<script>
import { onMounted, ref, computed } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog ,Toast} from 'vant'
import loginApi from '@axios/login'
import jsonp from '@assets/js/jsonp'


export default {
  name: "pay",

  setup(){

    let router = useRouter();
    const store = useStore();
    const route = useRoute();
    let checked = ref(false);
    let couponShow = ref(false);

    let fee = ref(0);
    let coupon_radio = ref('');
    fee.value = route.query.fee;


    let couponList = ref([]);
    const getCouponList = () => {
      let formData = new FormData();
      formData.append('data',JSON.stringify({
        function : 'myVoucher',
        _from : 'h5',
        userId : store.state.uid,
        minRow : '1',
        maxRow : '100',
        getAccess : '',
        isEnable : '0',
        serviceCode : route.query.productId,
        roleCode : '100',
        orderId : route.query.orderId,
        serviceType : '',
        amount : fee.value,
        source : 'h5_users_002',
        version : store.state.version,
        newversion : store.state.newversion,
      }))

      formData.append('encryption',false)

      loginApi.loginAction(formData).then((res)=>{
        couponList.value = res.data.list;
      })
    }

    getCouponList();


    let nousercoupon = () => {
      coupon_radio.value = '';
      couponShow.value = false;
    }
    let check_radio = (index) => {
      coupon_radio.value = index;
      couponShow.value = false;
    }
    let couponText = computed(()=>{
      if(couponList.value.length == 0){
        return '暂无可用代金卷'
      }
      if(coupon_radio.value === ''){
        return '请选择代金卷'
      }
      return '¥ ' + couponList.value[coupon_radio.value].price
    })


    let popActive = () => {
      if(couponList.value.length == 0){
        return;
      }
      couponShow.value = true;
    }




    let totle = computed(()=>{
      let sum = parseFloat(fee.value);

      if(coupon_radio.value !== ''){
        sum -= parseFloat(couponList.value[coupon_radio.value].price)
      }

      if(checked.value){
        if(sum > user.value.ableUseFee){
          sum -= parseFloat(user.value.ableUseFee)
        }else{
          sum = 0;
        }

      }

      return sum;
    })



    //支付

      //余额支付
    const balancePay = () => {
      let voucherId = '';
      let paynum = route.query.fee;
      if(coupon_radio.value !== ''){
        voucherId = couponList.value[coupon_radio.value].voucherId;
        paynum -= couponList.value[coupon_radio.value].price
      }


      let formData = new FormData();
      formData.append('data',JSON.stringify({
        function : 'payOrder',
        userid : store.state.uid,
        orderId : route.query.orderId,
        payFee : paynum,
        voucherId : voucherId,
        _from : 'h5',
        token : store.state.token,
        version : store.state.version,
        newversion : '2120',
        source : 'h5_users_wx_101',
      }))

      formData.append('encryption',false);
      loginApi.loginAction(formData).then((res)=>{
        router.push({
          name : 'orderList'
        })
      })
    }


    const isWx = () =>{
      let ua = window.navigator.userAgent.toLowerCase();
      return (ua.match(/MicroMessenger/i) == 'micromessenger') ? true : false;
    }
    const chosePayment = () => {
      if(isWx()){//微信浏览器
        //小程序环境判断
        wx.miniProgram.getEnv((res)=>{
          if(res.miniprogram){
            // alert("在小程序里iii")

            // alert("在小程序里123")
            wx.miniProgram.navigateTo({
              url: `/pages/shop-webview/pay/pay?orderId=${route.query.orderId}&fee=${totle.value}&orderType=20&fromPage=1`, // fromPage=1代表小护页面 如果需要传递参数的话可以通过 url 路径拼接
              success: (result)=> {
                // alert(JSON.stringify(result))
              },
              fail:(err) => {
                alert("打开失败")
              }
            });
            return;
          }else{
            payAction();//微信公众号支付
          }
        })
      }else{//非微信浏览器
        payH5Action()
      }
    }


    //H5支付
    const payH5Action = () =>{
      let formData = new FormData();
      var gate_id = '-1';
      if(store.state.sid == '88000000009'){
        gate_id = '100';
      }
      let voucherId = '';
      if(coupon_radio.value !== ''){
        voucherId = couponList.value[coupon_radio.value].voucherId
      }

      if(!totle.value){
        //余额支付
        balancePay();
        return;
      }
      let redirectUrl = encodeURIComponent("https://mi.yihu365.cn/community/communityOrderList")
      formData.append('data',JSON.stringify({
        function : 'rechargeOnline',
        source : 'h5_users_002',
        user_id : store.state.uid,
        fee : totle.value + '',
        pay_type : '261',
        voucherId : voucherId,
        orderId : route.query.orderId,
        gate_id : '1',
        app_flag : '3',
        device_id : 'h5',
        h5PayReturnUrl:redirectUrl,
        order_type:'20',
        _from : 'h5',
        token : store.state.token,
        version : store.state.version,
        newversion : '2120',
        sid : store.state.sid,
      }))
      formData.append('encryption',false)
      loginApi.loginAction(formData).then((res)=>{
        window.location.href = res.data;
      })
    }
    //微信支付

    const payAction = () => {
      let formData = new FormData();
      var gate_id = '-1';
      if(store.state.sid == '88000000009'){
        gate_id = '100';
      }
      let voucherId = '';
      if(coupon_radio.value !== ''){
        voucherId = couponList.value[coupon_radio.value].voucherId
      }

      if(!totle.value){
        //余额支付
        balancePay();
        return;
      }

      formData.append('data',JSON.stringify({
        function : 'rechargeOnline',
        source : 'h5_users_wx_101',
        user_id : store.state.uid,
        fee : totle.value + '',
        pay_type : '45',
        gate_id : gate_id,
        voucherId : voucherId,
        sid : store.state.sid,
        app_flag : '3',
        device_id : 'h5',
        orderId : route.query.orderId,
        _from : 'h5',
        token : store.state.token,
        version : store.state.version,
        newversion : '2120',
        openid : store.state.openId
      }))

      formData.append('encryption',false)

      loginApi.loginAction(formData).then((res)=>{
        let result = JSON.parse(res.data)
        WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
              "appId":result.appId,     //公众号ID，由商户传入
              "timeStamp":result.timeStamp,         //时间戳，自1970年以来的秒数
              "nonceStr":result.nonceStr, //随机串
              "package":result.package,
              "signType":result.signType,         //微信签名方式：
              "paySign":result.paySign //微信签名
            },
            function(res){
              if(res.err_msg == "get_brand_wcpay_request:ok" ){
                router.push({
                  name : 'communityOrderList'
                })
              }
              if(res.err_msg == "get_brand_wcpay_request:cancel" ){
                router.push({
                  name : 'communityOrderList'
                })
              }
              if(res.err_msg == "get_brand_wcpay_request:fail" ){
                router.push({
                  name : 'communityOrderList'
                })
              }
            }
        );
      })
    }


    return {
      popActive,
      couponText,
      coupon_radio,
      couponShow,
      couponList,
      fee,
      check_radio,
      nousercoupon,
      totle,
      chosePayment
    }
  }
}
</script>

<style scoped lang="scss">
.btm_btn_cont{
  padding:30px;
}
.concel_title{
  font-size: 30px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  text-align: center;
  line-height: 100px;
  height: 100px;
  border-bottom: 1px solid #EDEDED;
}
.pop_item{
  display: flex;
  justify-content: space-between;
  padding:15px 30px;
  align-items: center;
  .rad{
    width: 60px;
  }
}
.bg{
  background: #F7F7F7;
  border-radius: 10px;
  padding:23px 15px;
}
.pay_left_name{
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.pay_left_tips{
  font-size: 24px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #333333;
  margin-top:12px;
}
.pay_top{
  width: 100%;
  height: 200px;
  background: url(https://static.yihu365.cn/img/h5Img/assets/img/home/pay_top.png) no-repeat center center;
  background-size:100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;

.top_dec{
  display: flex;
.pay_icon{
  width: 66px;
  height: 66px;
  margin-right:15px;
}
}
.top_name{
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 66px;
  vertical-align: middle;
  color: #FFFFFF;
}
.top_num{
  font-size: 24px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #FFFFFF;
}
}
.pay_content{
  padding:58px 47px;
.pay_list{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-bottom: 35px;
}
}
.pay_right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #999999;
}
.btn_btm_cont{
  width: 100%;
  position: fixed;
  bottom: 0;
  left:0;
  padding:20px 0;
  display: flex;
  justify-content: flex-end;
  background: #fff;
  z-index: 999;
  border-top:1px solid #f5f5f5;
.btn_inner{
  padding:0 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.order_btn{
  font-size: 26px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  height: 50px;
  padding:0 22px;
  line-height: 50px;
  border-radius: 25px;
  border: 1px solid #999999;
  margin-left:20px;
}
.money{
  margin-right:40px;
  color:#FF6161;
  font-size: 40px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
.small{
  font-size:28px;
  margin:0 5px;
}
.submit_btn{
  width: 200px;
  height: 80px;
  line-height: 80px;
  white-space: nowrap;
}
}


.list {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 80px;
  overflow: hidden;
.list_money {
  font-size: 70px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #ffffff;
span {
  font-size: 30px;
  margin-right: 15px;
}
}
}
.list {
  margin-bottom: 20px;
  height: 160px;
  border-radius: 10px;
  background: url(https://static.yihu365.cn/img/h5Img/assets/img/my/coupon_bg.png) no-repeat center center;
  background-size: 100%;
  font-size: 34px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #313131;
  margin-left:20px;
}
.dero_cont {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-left: 34px;
& > div {
    position: relative;
  }
.span {
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  border-radius: 6px;
  position: absolute;
  left: -20px;
  top: 10px;
}
}
</style>
