import { render } from "./pay.vue?vue&type=template&id=2365d271&scoped=true"
import script from "./pay.vue?vue&type=script&lang=js"
export * from "./pay.vue?vue&type=script&lang=js"

import "./pay.vue?vue&type=style&index=0&id=2365d271&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-2365d271"
/* hot reload */
if (module.hot) {
  script.__hmrId = "2365d271"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2365d271', script)) {
    api.reload('2365d271', script)
  }
  
  module.hot.accept("./pay.vue?vue&type=template&id=2365d271&scoped=true", () => {
    api.rerender('2365d271', render)
  })

}

script.__file = "src/views/community/pay/pay.vue"

export default script